
html {
  min-height: 100vh;
}

body {
  padding-top: 66px;
  min-height: 100vh;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --grey: #ebe5e5;
  --green: #2b8636;
  --brown: #49261b;
  --black: #242323 ;
  --galacticPurple: #4f2b86;
}

mark {
  background-color: var(--dark) !important;
  color: inherit !important;
}

p {
  max-width: 700px !important;
  line-height: 2;
}
.App {
  text-align: center;
  position: relative;
  color: white;
  font-size: 16px;
  font-family: 'MerriWeather Sans';
  overflow-x: hidden;
}
.grid {
  display: grid;
  align-items: center;
  justify-items: center;
}

.App-logo {
  height: 50px;
}

.cb_header {
  min-height: 90vh;
  display: grid;
  align-content: center;
  justify-items: center;
  background: var(--black);
  background: url('./img/browniePlate.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  
}


.cb_header .shadowDiv {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-box-shadow: inset 1px -30px 56px 25px rgba(0,0,0,0.91);
  -moz-box-shadow: inset 1px -30px 56px 25px rgba(0,0,0,0.91);
  box-shadow: inset 1px -30px 56px 25px rgba(0,0,0,0.91);
}

.cb_header-video {
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
}
.cb_header-logo {
  max-width: 80%;
}

.cb_footer {
  display: grid;
  height: 80px;
  align-items: center;
  justify-items: center;
  background: var(--black);
}

.cb_footer_data {
  grid-gap: 10px;
  grid-template-columns: max-content max-content max-content;
  display: grid;
  height: 80px;
  align-items: center;
  justify-items: center;
  background: var(--black);
}

.cb_footer_data img {
  max-height: 60px;
}

.cb_vertical_divider {
  font-size: 40px;
  font-weight: normal;
}

.cb_contentSection {
  position: relative;
  display: grid;
  z-index: 10;
  min-height: 50vh;
  align-content: center;
  justify-items: center;
  padding-bottom: 50px;
}



.cb_detailsSection {
  position: relative;
  align-content: start;
  justify-items: start;
  padding: 36px;
  background: url('./img/star_background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 80vh;
  grid-template-rows: max-content 1fr;
  -webkit-box-shadow: inset 1px -30px 56px 25px rgba(0,0,0,0.91);
  -moz-box-shadow: inset 1px -30px 56px 25px rgba(0,0,0,0.91);
  box-shadow: inset 1px -30px 56px 25px rgba(0,0,0,0.91);
}

.cb_detailsSection > div {
  position: relative;
  justify-self: center;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr min-content;
  align-items: center;
  justify-items: center;
}


.cb_detailsSection > div.small > ul  {
  z-index: 3;
}

.cb_detailsSection > div > ul > li {
  display: grid;
  grid-template-columns: min-content max-content;
  align-items:center;
  grid-gap:10px;
  margin:20px;
  padding: 5px;
  font-size: 24px;
  background-color: var(--black);
  background-image: linear-gradient(to right, var(--black) 0%, var(--dark));
  border-radius:8px;
  box-shadow: 6px 6px 11px rgba(0,0,0,0.5);
}

.cb_detailsSection > div.small > ul > li {
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items:center;
  grid-gap:10px;
  margin:20px;
  font-size: 16px;
  background-color: var(--black);
  background-image: linear-gradient(to right, var(--black) 0%, var(--dark));
  border-radius:8px;
  box-shadow: 6px 6px 11px rgba(0,0,0,0.5);
}

.cb_detailsSection > div > .cb_astronaut {
  right: 0;
  position: absolute;
}

.cb_detailsSection > div.small > .cb_astronaut {
  left: 25px;
}

.cb_astronaut {
  animation: float 3s ease-in-out infinite;
}
.cb_detailsSection-list {
margin-top: 20px;
list-style: none;
text-align: left;
z-index: 3;
}

.cb_spaceship {
  position: absolute;
  bottom: 0;
  z-index: 0;
  width: 105%;
  height: 105%;
  background-image: url('./img/spaceship.png');
  background-repeat: no-repeat;
  background-position: 12px 91px;
  background-size: 30px 67px;
  -webkit-animation: turningspaceship 1.7s ease-in-out infinite;
  animation: turningspaceship 1.7s ease-in-out infinite;
}

.cb_emoji {
  font-size: 40px;
}

.cb_firstContent {
  justify-items: start;
  padding: 36px;
  background: url('./img/brow1.png') -90% center no-repeat, url('./img/star_background_dark.jpg') left top no-repeat;
  background-size: auto, cover;
  color: white;

}
.cb_firstContent > p {
  max-width: 80%;
  color: white;
  text-align: left;
/*   text-shadow: 1px 1px 0 var(--green), 1px 1px 0 var(--green);
  text-shadow: 1px 1px 0 var(--green), 1px 1px 0 var(--green), 2px 2px 0 var(--black); */
}

.cb_secondContent {
  justify-items: end;
  padding: 36px;
  
  background: url('./img/brow3.png') 156% center no-repeat, url('./img/star_background_dark.jpg') left top no-repeat;
  background-size: auto, cover;
}

@media (min-width: 700px) {
  .cb_firstContent {
    background: url('./img/brow1.png') 105% center no-repeat, url('./img/star_background_dark.jpg') left top no-repeat;
    background-size: auto, cover;
  }

  .cb_secondContent {
    background: url('./img/brow3.png') -15% center no-repeat, url('./img/star_background_dark.jpg') left top no-repeat;
    background-size: auto, cover; }

  .cb_contentSection {
    font-size: 20px;
  }

  .nav-item {
    padding-left: 10px;
  }
}

.cb_secondContent > p {
  max-width: 80%;
  text-align: right;
  color: white;
/*   text-shadow: 1px 1px 0 var(--green), 1px 1px 0 var(--green);
  text-shadow: 1px 1px 0 var(--green), 1px 1px 0 var(--green), 2px 2px 0 var(--black); */
}

.cb_contactForm {
  text-align: left;
  padding: 20px;
  display: grid;
  align-content: center;
  justify-items: center;
  background: url('./img/weedbg-2.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.cb_contactForm .btn {
  text-align: center;
}

.cb_contactForm-form {
  display: grid
}

.cb_highlightedText {
  text-shadow: 1px 1px 0 var(--green), 1px 1px 0 var(--green);
  text-shadow: 1px 1px 0 var(--green), 1px 1px 0 var(--green), 2px 2px 0 var(--black);
}

h2 {
  position: relative;
  border-bottom: 2px solid white;
  text-shadow: 1px 1px 0 var(--green), 1px 1px 0 var(--green);
  text-shadow: 1px 1px 0 var(--green), 1px 1px 0 var(--green), 2px 2px 0 var(--black);
  z-index: 3;
}

h2.efectos,
h2.precauciones,
h2.manual {
  border: none;
}

.efectos::before {
  content: '';
  position: absolute;
  width: 100vw;
  background: var(--black);
  height: 100%;
  padding: 5px;
  z-index: -1;
  right: -12px;
  border-radius: 20px;
  box-shadow: 1px 2px 0 var(--green);
  opacity: 0.8;
}

.manual::before {
  content: '';
  position: absolute;
  width: 100vw;
  background: var(--black);
  height: 100%;
  padding: 5px;
  z-index: -1;
  right: -12px;
  border-radius: 20px;
  box-shadow: 1px 2px 0 white;
  opacity: 0.8;
}

.precauciones::before {
  content: '';
  position: absolute;
  width: 100vw;
  background: var(--black);
  height: 100%;
  padding: 5px;
  z-index: -1;
  left: -12px;
  border-radius: 20px;
  box-shadow: 1px 2px 0 var(--green);
  opacity: 0.8;
}

.navbar {
background: var(--black);
}

.navbar-dark .navbar-nav .nav-link  {
  color: white !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cb_productWindow {
  position: fixed;
  display: grid;
  grid-template-rows: min-content max-content 1fr; 
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1050;
  background-color: #f5f5f5;
}

.cb_productWindow-closeButton {
  justify-self: end;
  display: grid;
  color: var(--green);
  cursor: pointer;
  align-content: center;
  justify-items: center;
  font-size: 20px;
  height: 50px;
  width: 50px;
  margin: 15px;
  border-radius: 50%;
  border: 2px solid var(--green);
}

.cb_productWindow-product {
  display: grid;
  justify-items: center;
  grid-gap: 10px;
  color: black;
  padding: 10px;
}

.cb_productBlock {
  display: grid;
  justify-items: center;
  grid-gap: 10px;
  color: white;
  font-weight: bold;
  padding: 10px;
  border: 1px solid #ddd; 
}

.cb_productWindow-product > img,
.cb_productBlock > img {
  width: 155px;
  height: 155px;
  border-radius: 50%;
  border: 3px solid #b7b5b5;
}

.cb_productWindow-cost {
  font-size: 25px;
  color: var(--green);
  font-weight: 700;
}

.mainDarkBG {
  background:  var(--black);
  display: grid;
  align-content: center;
  justify-items: center;
  min-height: calc(100vh - 66px);
}

.cb_userPanel {
  background:  url('./img/star_background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-content: center;
  justify-items: center;
  height: calc(100vh - 66px);
}

.loginMenuActions {
  display: grid;
  grid-template-columns: max-content max-content;
  align-content: center;
  justify-items: center;
  grid-gap: 20px;
  margin-top: 35px;
}

.cb_form {
  display: grid;
  align-content: center;
  justify-items: center;
  width: 80%;
  max-width: 600px;
  min-height: 400px;
  background: var(--dark);
  padding: 20px;
  border-radius: 6px;
  box-shadow: 1px 3px 13px rgba(0,0,0,.9);
}

.cb_form h2 {
  font-size: 24px;
}

.cb_userImage {
  display: grid;
  align-content: center;
  justify-items: center;
}

.cb_productQty {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  grid-gap: 5px;
  align-content: center;
  justify-items: center;
  font-size: 30px;
}

.cb_productBlockContainer {
  display: grid;
  grid-gap: 25px;
  padding-top: 50px;
  width: 90%;
}

.cb_productQty span {
  border-radius: 50%;
  border: 1px solid #ddd;
  width: 48px;
  height: 48px;
  display: grid;
  align-content: center;
  justify-items: center;
  font-size: 30px;
}

.cb_userImage img {
  border-radius: 50%;
  border: 5px solid white;
  box-shadow: 0px 5px 6px rgba(0,0,0,0.5);
}

.cb_userMenu li {
  list-style: none;
  text-align: center;
  cursor: pointer;
}

.cb_blankState {
  position:absolute;
  top:0;
  width: 100%;
  background:  var(--dark);
  display: grid;
  align-content: center;
  justify-items: center;
  min-height: 100vh;
}

/* ANIMATIONS */

.fade-enter {
  opacity: 0.01;
  top: 1500px;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  top: 0;
  transition: all 300ms ease-in-out;
}

.fade-appear {
  opacity: 0.01;
  top: 1500px;
}

.fade-leave {
  top: 0;
  opacity: 1;
}

.fade-leave.fade-leave-active {
  top: 1500px;
  transition: all 300ms ease-in-out;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  top: 0;
  transition: all 0.5s ease-in-out;
}

.cb_productWindow-draggableRow {
  display: grid;
  justify-items: end;
}

@-webkit-keyframes turningspaceship {
  from {transform: rotate(270deg);}
  to {transform: rotate(630deg);}
}


@keyframes turningspaceship {
  from {transform: rotate(270deg);}
  to {transform: rotate(630deg);}
} 

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}